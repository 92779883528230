<template>
  <div 
    class='flex flex-row items-start justify-start gap-y-16 gap-x-16 flex-wrap'
    :class='countBasedGap'>
    <greeter-details v-for='(greeter, index) in greeters'
      :key='`welcome-greeter-${index}`'
      :greeter-name='greeter.greeterName'
      :greeter-title='greeter.greeterTitle'
      :image-url='greeter.imageUrl'
      :signature-image-url='greeter.signatureImageUrl' 
      :size='size' />
  </div>
</template>

<script>
import GreeterDetails from '@/components/welcome/GreeterDetails.vue'

export default {
  name: 'Greeters',
  components: {
    GreeterDetails,
  },
  props: {
    size: {
      type: String,
      default: 'large',
    },
    greeters: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    countBasedGap () {
      if (this.greeters.length <= 3) {
        return 'gap-x-16'
      } else {
        return 'lg:gap-x-6'
      }
    },
  }
}
</script>
