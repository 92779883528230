<template>
  <div class='flex flex-row lg:flex-col justify-start items-start lg:items-center gap-x-4' 
    :class='sizeBasedWidth'>
    <div>
      <img v-if='imageUrl' class='block w-64 lg:w-full lg:max-h-64 mb-4 lg:mb-0' :src='imageUrl' />
      <img v-if='signatureImageUrl' class='block w-64 lg:w-full lg:h-16' :src='signatureImageUrl' />
    </div>
    <div>
      <h2 class='font-medium mt-4 lg:mt-8 leading-4'>
        {{ greeterName }}
      </h2>
      <h3 class='text-gray-400 text-sm'>
        {{ greeterTitle }}
      </h3>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GreeterDetails',
  props: [
    'greeterName',
    'greeterTitle',
    'signatureImageUrl',
    'imageUrl',
    'size',
  ],
  computed: {
    sizeBasedWidth () {
      if (this.size === 'large') {
        return 'w-36 lg:w-40'
      } else {
        return 'w-full lg:w-36'
      }
    },
  },
}
</script>



