<template>
  <div>
    <welcome-conference-banner v-if='showConferenceBanner' />
    <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-48' 
      :style='minHeightStyle'>
      <div
        class='background-style text-white py-4 px-4 lg:py-16 lg:px-16 flex-shrink-0'
        :style='`${sidebarBgStyle}`'>
        <h2 class='text-2xl uppercase'>{{ welcomeMessageTitle }}</h2>
      </div>
      <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
        <div v-html='welcomeMessageBody' class='leading-6 max-w-xl text-sm mb-4 whitespace-pre-line'></div>
        <greeters :greeters='greetersList' />
        <img class='message-author' :src='welcomeMessageTitleImage' />
      </div>
    </div>
    <welcome-sponsor-carousel v-if='showSponsorCarousel' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Greeters from '@/components/welcome/Greeters.vue'

export default {
  name: 'WelcomeMessage',
  components: {
    Greeters,
    'welcome-conference-banner': () => import('@/components/welcome/WelcomeConferenceBanner.vue'),
    'welcome-sponsor-carousel': () => import('@/components/welcome/WelcomeSponsorsCarousel.vue'),
  },
  props: {
    customBackgroundStyle: {
      type: String,
      default: '',
    },
    showConferenceBanner: {
      type: Boolean,
      default: false,
    },
    conferenceBannerHeight: {
      type: Number,
      default: 120,
      required: false,
    },
    showSponsorCarousel: {
      type: Boolean,
      default: false,
    },
    welcomeMessageBody: {
      type: String,
      default: '',
    },
    welcomeMessageTitle: {
      type: String,
      default: '',
    },
    welcomeMessageTitleImage: {
      type: String,
      default: '',
    },
    greetersList: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    sidebarBgStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      if (this.showSponsorCarousel) {
        minHeight += 112
      }
      if (this.showConferenceBanner) {
        minHeight += this.conferenceBannerHeight
      }
      return `min-height: calc(100vh - ${minHeight}px);`
    },
  },
}
</script>

<style lang='scss' scoped>

.background-style {
  background: transparent; 
  width: 100%;
}
  
.background-style img {
  position: static;
  right: 1rem; 
  bottom: 0rem; 
  width: 120px;
}
    
.message-author {
  position: absolute;
  right: 1rem; 
  bottom: 1rem; 
  width: 5rem;
}

  @media (min-width: 1024px) {
    .background-style {
      width: 20rem;
      height: auto;
    }

    .message-author {
      position: absolute;
      left: -20rem; 
      top: 8rem; 
      width: 180px;
      display: block;
    }

  .background-style img {
    position: absolute;
    right: -4rem; 
    top: 4rem; 
    width: 180px;
    display: block;
  }

}
</style>


